<script setup lang="ts">
import {Head, useForm} from '@inertiajs/inertia-vue3';
import AuthenticationCard from '@/Components/AuthenticationCard.vue';
import AuthenticationCardLogo from '@/Components/AuthenticationCardLogo.vue';
import Tabs from "../General/Tabs.vue";
import Tab from '../../../ts/models/Tab';
import RegisterForm from "@/Pages/Auth/Forms/RegisterForm.vue";
import LoginForm from "@/Pages/Auth/Forms/LoginForm.vue";
import {Company} from "../../../ts/models/Company";
import {ref, computed} from "vue";
import Banner from "@/Components/Banner.vue";
import {ExclamationTriangleIcon} from '@heroicons/vue/24/outline'
import {translate} from "../../../ts/helpers/translationHelper";
const props = defineProps({
    settings: Array,
    canResetPassword: Boolean,
    status: String,
    company: Company,
		limitReached: {
				type: Boolean,
			default: false
		}
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.transform(data => ({
        ...data,
        remember: form.remember ? 'on' : '',
    })).post(route('login'), {
        onFinish: () => form.reset('password'),
    });
};

const tabsArr = [new Tab("auth.login", true, false, 0),];
if (props.company?.id && props.settings.allow_self_registration == '1')
    tabsArr.push(new Tab("auth.register", false, false, 1));

const tabs = ref(tabsArr);

const changeTab = async (selectedTab) => {
    if (selectedTab?.disabled || !selectedTab) return;

    tabs.value.map(t => {
        t.current = t.index === selectedTab.index;
    });
}
const currentTab = computed(() => {
    let tab = tabs.value.find(t => t.current === true && t.disabled === false) ?? tabs.value.find(t => t.disabled === false);
    // if no tab, fallback to the first...
    if (!tab)
        tab = tabs[0];
    if (tab)
        tab.current = true;
    return tab;
});

</script>

<template>
    <Head title="Log in"/>

	<Banner/>

	<AuthenticationCard>
        <template #logo>
            <AuthenticationCardLogo :logo="company?.logo?.original_url"/>
        </template>

        <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
            {{ status }}
        </div>
        <Tabs :use-secondary-color="true" :use-full-width="true" :tabs="tabs" @changeTab="changeTab"></Tabs>
        <LoginForm :company="company" :canResetPassword="canResetPassword" v-if="currentTab.index === 0"></LoginForm>
			<template v-if="currentTab.index === 1">
				<div v-if="limitReached" class="bg-orange-100 rounded-3xl p-3 mb-4 text-orange-900">
					<div class=" flex">
						<ExclamationTriangleIcon class="h-8 mr-2" aria-hidden="true"/>
						{{ translate('users.userLimitReached') }}
					</div>
				</div>
				<RegisterForm :company="company" v-else></RegisterForm>
			</template>

    </AuthenticationCard>
</template>
